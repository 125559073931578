$red: rgb(177, 54, 45);
$black: black;
$gray2: #666666;
$gray3: #999999;
$gray4: #ddd;

$errorColor: $red;
$labelActive: scale(1) translateY(-150%);
$activeFieldBorder: 2px solid white;
$errorFieldBorder: 2px solid $gray2;
$inactiveFieldBorder: 2px solid $gray3;
$animationTiming: 0.3s ease-in-out;

.root {
  position: relative;
  width: 100%;
  margin-top: 1rem;
  padding-bottom: 0.5rem;
}

// <label> = field
.field {
  display: block;

  // input underline
  &:after {
    content: '';
    position: absolute;
    width: 100%;
    border-bottom: $inactiveFieldBorder;
    transition: border $animationTiming;
  }

  // has user input state
  &.hasInput {
    .visualLabel {
      color: $gray3;
      transform: $labelActive;
    }
  }

  // focused state
  &.hasFocus {
    &:after {
      border-bottom: $activeFieldBorder;
    }

    .visualLabel {
      color: $gray3;
      transform: $labelActive;
    }
  }

  // error state
  &.showError {
    &:after {
      border-bottom: $errorFieldBorder;
    }
    .visualLabel {
      // color: $errorColor;
      transform: $labelActive;
    }
  }
}

.visualLabel {
  @apply font-medium;
  font-size: 1rem;
  position: absolute;
  top: 25%;
  left: 0;
  color: $gray4;
  transform-origin: 0 50%;
  transform: translateY(0);
  transition: transform $animationTiming, color $animationTiming;
}

.input {
  outline: none;
  font-size: 2rem;
  width: 100%;
  display: block;
  padding: 0;
  padding-bottom: 0.3rem;
  background-color: transparent;
  // color: white;

  &::placeholder {
    color: transparent;
  }
}

.error {
  @apply font-medium;
  position: absolute;
  margin-top: 0.6rem;
  color: $errorColor;
  font-size: 0.8rem;
}

.icon {
  display: inline-block;
  width: 28px;
  height: 22px;
  position: absolute;
  top: 22%;
  right: 0;
  color: white;
  outline: none;
  transition: color $animationTiming, transform $animationTiming;
  // cursor: pointer;

  &:focus,
  &:hover {
    outline: none;
    transform: scale(1.07);
  }

  &.errorColor {
    color: $gray2;
  }
}
