.image {
  opacity: 0;
  transition: opacity 0.2s, background-color 0.2s;
  background-color: #f6f6f6;

  &.hasLoaded {
    opacity: 1;
    background-color: transparent;
  }
}
