.logo {
  margin: auto;
  margin-bottom: 6rem;
}

.password {
  position: relative;
}

.requestPassword {
  @apply font-medium;
  position: absolute;
  margin-top: 0.2rem;
  right: 0;
  font-size: 0.8rem;
  color: #666666;
}

@screen md {
  .logo {
    margin-bottom: 8rem;
  }

  .password {
    width: 26rem;
  }
}
