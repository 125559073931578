.container {
  overflow: hidden;

  .imageParallax {
    margin-top: -15%;
    margin-bottom: -15%;
  }
}

.arrow {
  margin-top: 3px;
  display: inline-block;
  width: 18px;
}

@screen md {
  .arrow {
    display: none;
  }
}
