@import '../../styles/variable.scss';

.cursor {
  position: fixed;
  z-index: 999;
  display: flex;
  align-items: center;
  justify-content: center;

  border-color: transparent;
  border-width: 2px;
  border-radius: 50% 50%;
  width: 123px;
  height: 123px;

  pointer-events: none;
  transition: opacity 0.1s $ease-out-quint, color 0.1s $ease-out-quint,
    transform 0.3s $ease-out-quint, background-color 0.1s $ease-out-quint,
    top 0.5s $ease-out-quint, left 0.5s $ease-out-quint;
  will-change: transform, opacity, background-color, top, left;

  backface-visibility: hidden;
  backdrop-filter: blur(0);
  opacity: 0;
  @apply bg-purple;

  &.dark {
    color: white;
  }
  &.white {
    color: black;
  }
  &.purple {
    @apply text-purple;
  }

  &.isVisible {
    opacity: 1;
  }
  &.isActive {
    border-color: currentColor;
    background-color: rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(10px);

    transition: opacity 0.1s $ease-in-out-quad, color 0.1s $ease-in-out-quad,
      transform 0.2s $ease-in-out-quad, background-color 0.1s $ease-in-out-quad,
      top 0.2s $ease-out-quint, left 0.2s $ease-out-quint;
  }
}
