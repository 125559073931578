.nextEl,
.prevEl {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 25%;
  opacity: 0;
  z-index: 10;
}

.slide {
  width: 75%;
  margin: 0 auto;
  overflow: hidden;
  position: relative;

  user-select: none;
  cursor: grab;
}

@screen md {
  .slide {
    width: 50%;
  }
}

.nextEl {
  left: 75%;
  //   background-color: red;
}

.prevEl {
  left: 0;
  //   background-color: blue;
}

.pages {
  margin-top: 0.5rem;
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  text-align: center;
}

@screen md {
  .pages {
    margin-top: 0.8rem;
  }
}

@screen lg {
  .pages {
    margin-top: 1.2rem;
  }
}

@screen xl {
  .pages {
    margin-top: 3.2rem;
  }
}
