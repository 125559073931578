@import '../../styles/variable.scss';

.footer {
  height: $footerHeightDesktop;
  background-color: #f6f6f6;
  opacity: 0;
  transition: opacity 0.1s;
  &.isVisible {
    opacity: 1;
  }
}

.link {
  display: inline-block;
  min-width: 4.8rem;
  padding-bottom: 0.2rem;
  border-bottom: 2px solid black;
}

.headline {
  font-size: 21px;
  line-height: 25px;
  @apply font-semibold;
}

@screen md {
  .headline {
    font-size: 32px;
    line-height: 36px;
  }
}
