.root {
  // padding-top: 0;
  background-color: #f9f9f9;
}

.grid {
  // padding-top: 5rem;
  max-width: 1440px;
  margin: 0 auto;
  display: grid;
  grid-template-columns: 50% 50%;
}

.phone {
  &:nth-of-type(2n) {
    margin-right: -0.8rem;
  }
  &:nth-of-type(2n + 1) {
    margin-left: -0.8rem;
    margin-top: -100%;
  }
}

.headline {
  margin-top: 5%;
  svg {
    margin: auto;
    width: 100%;
  }
}

@screen md {
  .root {
    // padding-top: 10%;
  }
  .phone {
    padding: 0 4rem;
    &:nth-of-type(2n) {
      padding-right: 10%;
    }
    &:nth-of-type(2n + 1) {
      padding-left: 10%;
      margin-top: -100%;
    }
  }
  .headline {
    margin-top: 5%;
    padding: 0 4rem;
    padding-left: 10%;

    svg {
      width: 75%;
    }
  }
}
