// .phone {
//   padding: 0 2rem;
// }

// @screen md {
.root {
  padding-top: 20%;
  background-color: #f9f9f9;
}

.grid {
  display: flex;
  flex-flow: row wrap;
  transform: rotate(15deg);
}

.phone {
  padding: 0;
  width: 33%;
  &:nth-of-type(2) {
    margin-top: 40%;
  }
  &:nth-of-type(3) {
    margin-top: 60%;
  }
  &:nth-of-type(4) {
    margin-top: -50%;
  }
  &:nth-of-type(5) {
    margin-top: -20%;
  }
}
// }
