$size: 6px;
$delay: 90ms;

$linear: cubic-bezier(0.25, 0.25, 0.75, 0.75);
$ease: cubic-bezier(0.25, 0.1, 0.25, 1);
$easeIn: cubic-bezier(0.42, 0, 1, 1);
$easeOut: cubic-bezier(0, 0, 0.58, 1);
$easeInOut: cubic-bezier(0.42, 0, 0.58, 1);

/* Penner Equations (approximated) originally created by @robpenner */
$easeInQuad: cubic-bezier(0.55, 0.085, 0.68, 0.53);
$easeInCubic: cubic-bezier(0.55, 0.055, 0.675, 0.19);
$easeInQuart: cubic-bezier(0.895, 0.03, 0.685, 0.22);
$easeInQuint: cubic-bezier(0.755, 0.05, 0.855, 0.06);
$easeInSine: cubic-bezier(0.47, 0, 0.745, 0.715);
$easeInExpo: cubic-bezier(0.95, 0.05, 0.795, 0.035);
$easeInCirc: cubic-bezier(0.6, 0.04, 0.98, 0.335);
$easeInBack: cubic-bezier(0.6, -0.28, 0.735, 0.045);

$easeOutQuad: cubic-bezier(0.25, 0.46, 0.45, 0.94);
$easeOutCubic: cubic-bezier(0.215, 0.61, 0.355, 1);
$easeOutQuart: cubic-bezier(0.165, 0.84, 0.44, 1);
$easeOutQuint: cubic-bezier(0.23, 1, 0.32, 1);
$easeOutSine: cubic-bezier(0.39, 0.575, 0.565, 1);
$easeOutExpo: cubic-bezier(0.19, 1, 0.22, 1);
$easeOutCirc: cubic-bezier(0.075, 0.82, 0.165, 1);
$easeOutBack: cubic-bezier(0.175, 0.885, 0.32, 1.275);

$easeInOutQuad: cubic-bezier(0.455, 0.03, 0.515, 0.955);
$easeInOutCubic: cubic-bezier(0.645, 0.045, 0.355, 1);
$easeInOutQuart: cubic-bezier(0.77, 0, 0.175, 1);
$easeInOutQuint: cubic-bezier(0.86, 0, 0.07, 1);
$easeInOutSine: cubic-bezier(0.445, 0.05, 0.55, 0.95);
$easeInOutExpo: cubic-bezier(1, 0, 0, 1);
$easeInOutCirc: cubic-bezier(0.785, 0.135, 0.15, 0.86);
$easeInOutBack: cubic-bezier(0.68, -0.55, 0.265, 1.55);

.root {
  display: flex;
  align-items: center;
  justify-content: center;
  color: black;
  background-color: #fdfdfd;
}

.dot {
  display: block;
  flex-grow: 0;
  flex-shrink: 0;

  margin: 0 2px;
  width: $size;
  height: $size;

  border-radius: 50px;
  border: none;
  overflow: hidden;

  animation: bounce 1s $easeInOutQuad infinite,
    color 1s $easeInOutQuint infinite;

  &:nth-child(2) {
    animation-delay: $delay;
  }
  &:nth-child(3) {
    animation-delay: $delay * 2;
  }
}

@keyframes bounce {
  0% {
    transform: translate(0, 0);
  }
  50% {
    transform: translate(0, -$size * 2);
  }
  60% {
    transform: translate(0, -$size * 2);
  }
  100% {
    transform: translate(0, 0);
  }
}

@keyframes color {
  0% {
    background-color: transparent;
  }
  50% {
    background-color: currentColor;
  }
  100% {
    background-color: transparent;
  }
}
