.root {
  overflow: hidden;
  margin: 0;
  .screenImage {
    background-color: transparent;
  }
}

.container {
  margin-top: -24%;
  margin-right: -12%;
  margin-left: -12%;
  width: auto;
}

.phone {
  z-index: 2;
}

.screen {
  position: absolute;
  top: 14.3%;
  right: 19.24%;
  left: 19.24%;
}
