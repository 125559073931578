.container {
  overflow: hidden;

  .imageParallax {
    margin-top: -12%;
  }
}

@screen md {
  .container {
    .imageParallax {
      margin-top: -9%;
    }
  }
}
