$blur: var(--blur-reveal);
// $opacity: var(--blur-opacity);
$brightness: var(--blur-brightness);

.reveal {
  //   opacity: $opacity;
  backdrop-filter: blur($blur) brightness($brightness);
  -webkit-backdrop-filter: blur($blur) brightness($brightness);
}

.revealHome {
  opacity: 1;
}
