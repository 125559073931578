@font-face {
  font-family: 'Montserrat';
  font-weight: 400;
  src: local('Montserrat'),
    url(./fonts/Montserrat-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'Montserrat';
  font-weight: 500;
  src: local('Montserrat'),
    url(./fonts/Montserrat-Medium.ttf) format('truetype');
}

@font-face {
  font-family: 'Montserrat';
  font-weight: 600;
  src: local('Montserrat'),
    url(./fonts/Montserrat-SemiBold.ttf) format('truetype');
}

@tailwind base;
@tailwind components;
@tailwind utilities;

::selection {
  background: #e3e3e3; /* WebKit/Blink Browsers */
}
::-moz-selection {
  background: #e3e3e3; /* Gecko Browsers */
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
}

.no-cursor > * {
  cursor: none !important;
}

.copy {
  @apply text-sm;
  @apply font-medium;
}

@screen md {
  .copy {
    @apply text-base;
  }
}

.narrow {
  width: 75%;
  margin: 0 auto;
}

@screen md {
  .narrow {
    width: 50%;
  }
}
